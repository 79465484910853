html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    background-color: white;
}

.admin-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    min-height: 100%;
    position: relative;
    overflow-y: auto;
    font-family: 'Helvetica Neue', sans-serif;
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: white;
    width: 100%;
    position: relative;
    padding-top: 20px;
}

.header-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.main-content {
    width: 100%;
    position: relative;
}

.preview-section {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.preview-box {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px;
    height: 600px;
    width: 100%;
    margin-bottom: 20px;
    background-color: white;
    overflow-y: auto;
    box-sizing: border-box;
}

.preview-box iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.logout-button {
    background-color: #ef4444;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    align-self: flex-start;
    transition: all 0.2s ease;
    font-weight: 500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.logout-button:hover {
    background-color: #dc2626;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1, h2 {
    margin: 0;
    text-align: center;
    width: 100%;
    font-family: 'Helvetica Neue', sans-serif;
}

.date-selector {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="date"] {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.download-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.download-button:hover {
    background-color: #45a049;
}

.date-section {
    margin: 20px 0;
    text-align: center;
}

.date-input {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 10px;
}

.stats-section {
    margin: 30px 0;
}

.stat-row {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
}

.stat-label {
    width: 150px;
    font-family: 'Helvetica Neue', sans-serif;
}

.stat-value {
    margin: 0 20px;
    font-weight: bold;
    min-width: 50px;
    text-align: right;
    width: 50px;
    font-family: 'Helvetica Neue', sans-serif;
}

.action-button {
    background-color: #3b82f6;
    color: white;
    border: none;
    padding: 10px 16px;
    border-radius: 6px;
    cursor: pointer;
    margin-left: auto;
    width: 200px;
    text-align: center;
    transition: all 0.2s ease;
    font-weight: 500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.action-button:hover {
    background-color: #2563eb;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.preview-controls {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.view-button {
    background-color: #64748b;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    flex: 1;
    transition: all 0.2s ease;
    font-weight: 500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.view-button:hover {
    background-color: #475569;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-preview {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.item-preview:last-child {
    border-bottom: none;
}

.status-message {
    height: 24px;
    margin: 10px 0;
    text-align: center;
    font-weight: 500;
    min-height: 24px;
    visibility: visible;
    color: red;
    animation: blinkFade 2.5s ease-in-out infinite;
}

.status-message:empty {
    visibility: hidden;
    height: 24px;
    margin: 10px 0;
    display: block;
}

@keyframes blinkFade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .admin-page {
        padding: 10px;
    }

    .stat-row {
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
    }

    .stat-label {
        width: auto;
    }

    .stat-value {
        text-align: center;
        width: auto;
    }

    .action-button {
        width: 100%;
        margin-left: 0;
    }

    .preview-controls {
        flex-direction: column;
    }
}

button {
    font-family: 'Helvetica Neue', sans-serif;
}

.register-button {
    background-color: #22c55e;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    align-self: flex-start;
    transition: all 0.2s ease;
    font-weight: 500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    margin-top: 10px;
}

.register-button:hover {
    background-color: #16a34a;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
