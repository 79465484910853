body {
  font-family: 'Helvetica Neue', sans-serif;
  display: block;
  height: auto;
  margin: 0;
  background-color: transparent;
}

.Login {
  width: 300px;
  padding: 0;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
}

.Login-header {
  width: 100%;
}

.Login-header h1 {
  font-size: 1.5rem;
  color: #00693E;
  margin: 0 0 1rem 0;
}

form {
  background-color: transparent;
  width: 100%;
  padding: 0;
}

form div {
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

label {
  font-size: 0.85rem;
  font-weight: 500;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  display: block;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  padding: 0 10px;
  margin-bottom: 10px;
}

input:focus {
  outline: none;
  border-color: #00693E;
  box-shadow: 0 0 0 2px rgba(0, 105, 62, 0.1);
}

button {
  width: 100%;
  height: 40px;
  background-color: #00693E;
  margin-top: 1rem;
  border-radius: 4px;
}

button:hover {
  background-color: #005432;
  transform: none;
  box-shadow: none;
}

.error-message {
  font-size: 0.8rem;
  color: #e74c3c;
  margin: 0.75rem 0;
  text-align: center;
}
