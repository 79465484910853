html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #f5f7fa;
    overflow-y: auto;
}

.container {
    max-width: 60%;
    margin: 0 auto;
    padding: 40px;
    background-color: white;
    border-radius: 8px;
    min-height: auto;
    position: relative;
    overflow-y: visible;
}

h1, h2 {
    font-size: 2rem;
    color: #1f2937;
    margin-bottom: 35px;
    text-align: center;
}

h2 {
    font-size: 1.5rem;
    margin-top: 40px;
    margin-bottom: 30px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #4b5563;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
    width: 100%;
    padding: 12px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 16px;
    box-sizing: border-box;
    background-color: #fff;
    transition: border-color 0.2s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

table th,
table td {
    padding: 12px;
    border: 1px solid #e5e7eb;
    width: 33.33%;
    min-width: 100px;
}

table th {
    background-color: #f8fafc;
    color: #4b5563;
}

table input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    font-size: 20px;
    height: 45px;
}

button {
    padding: 10px 15px;
    height: 40px;
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

button:hover {
    background-color: #3b82f6;
}

#productosTable {
    min-height: 50px;
}

@media screen and (max-height: 768px) {
    .container {
        max-height: 90vh;
    }

    h1, h2 {
        font-size: 1.5em;
        margin-bottom: 10px;
    }

    .form-group {
        margin-bottom: 10px;
    }
}

.submit-btn {
    background-color: #3b82f6;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    font-size: 16px;
    margin-top: 20px;
}

.submit-btn:hover {
    background-color: #2563eb;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logout-button {
    background-color: #ef4444;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    display: block;
    margin: 0 auto;
    margin-bottom: 45px;
    font-size: 16px;
}

.logout-button:hover {
    background-color: #dc2626;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: #f8fafc;
    padding: 30px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.popup-content > * {
    width: 100%;
    margin-bottom: 15px;
}

.summary {
    margin-bottom: 20px;
}

.popup-buttons {
    display: flex;
    justify-content: space-around;
}

.popup-buttons button {
    background-color: #3b82f6;
    color: white;
    border: none;
    padding: 10px 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    margin: 0 5px;
    text-align: center;
    white-space: normal;
    line-height: 1.2;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-buttons button:hover {
    background-color: #2563eb;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#addRow, #deleteRow {
    background-color: #64748b;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    margin: 10px;
    font-size: 16px;
}

#addRow:hover, #deleteRow:hover {
    background-color: #475569;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popup-buttons button:first-child {
    background-color: #3b82f6;
}

.popup-buttons button:last-child {
    background-color: #64748b;
}

.popup-buttons button:last-child:hover {
    background-color: #475569;
}
