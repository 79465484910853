body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.main-page {
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: white;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.header {
  width: 100%;
  height: auto;
  position: fixed;
  top: 20px;
  right: 0;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.95);
}

.login-button {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 1001;
}

.login-button button {
  padding: 8px 16px;
  background-color: white;
  color: #00693E;
  border: 2px solid #00693E;
  border-radius: 4px;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
}

.login-button button:hover {
  background-color: #00693E;
  color: white;
  transform: scale(1.05);
}

.content {
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.pharmacy-cross {
  width: 120px;
  margin-bottom: 30px;
}

.content h1 {
  color: #00693E;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.content h2 {
  color: #00693E;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1.8rem;
  margin: 20px 0 10px;
}

.content p, .content li {
  color: #00693E;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1.2rem;
  text-align: center;
  margin: 5px 0;
}

.services, .schedule, .contact, .address {
  margin: 20px 0;
  text-align: center;
}

.services ul {
  list-style: none;
  padding: 0;
}

.contact a {
  color: #00693E;
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}

.login-container {
  position: fixed;
  top: 80px;
  z-index: 1000;
  background-color: white;
  border: 1px solid #00693E;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: none;
}

.login-container.show {
  display: block;
}

.popup-overlay {
  position: absolute;
  top: 70px;
  right: 20px;
  left: auto;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  z-index: 1000;
  width: 300px;
  padding: 20px 15px;
  animation: popupAppear 0.3s ease forwards;
}

.popup-overlay.closing {
  animation: popupDisappear 0.3s ease forwards;
}

@keyframes popupAppear {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popupDisappear {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.popup-content {
  display: flex;
  overflow: hidden;
  max-height: fit-content;
}

.close-button {
  position: absolute;
  right: -6px;
  top: -6px;
  width: 18px;
  height: 18px;
  border: none;
  background: #f8f8f8;
  color: #666;
  font-size: 14px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 1;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.close-button:hover {
  background: #eee;
  color: #333;
}

.popup-overlay::before {
  content: '';
  position: absolute;
  top: -6px;
  right: 25px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
}

@media (max-width: 768px) {
  .content h1 {
    font-size: 2rem;
  }

  .content h2 {
    font-size: 1.5rem;
  }

  .content p, .content li {
    font-size: 1rem;
  }

  .popup-overlay {
    width: 90%;
    right: 5%;
    left: 5%;
  }
}

@media (max-width: 480px) {
  .content {
    padding: 15px;
  }

  .content h1 {
    font-size: 1.8rem;
  }

  .services, .schedule, .contact, .address {
    margin: 15px 0;
  }

  .popup-overlay {
    width: 280px;
    right: 10px;
    top: 70px;
  }

  .popup-overlay::before {
    right: 20px;
  }

  .login-button {
    right: 10px;
  }

  .login-button button {
    min-width: 100px;
  }
}

.login-button button:focus,
.close-button:focus {
  outline: 2px solid #00693E;
  outline-offset: 2px;
}

.contact a {
  padding: 2px 4px;
  display: inline-block;
}

.services, .schedule, .contact, .address {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
  animation-delay: calc(var(--order) * 0.2s);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
